import React, { DetailedHTMLProps, useContext } from 'react';
import ThemeContext from 'Core/Contexts/ThemeContext';
import './_Text.scss';

interface _TextProps
  extends DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  > {
  type?: 'title' | 'sub-title';
}

const _Text = (props: _TextProps) => {
  const { type, className } = props;
  const theme = useContext(ThemeContext);
  return (
    <span
      {...props}
      className={`_Text ${theme} ${type || ''} ${className || ''}`}
    />
  );
};

export default _Text;
