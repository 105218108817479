import React, { useContext } from 'react';
import './PxGStats.scss';
import ThemeContext from 'Core/Contexts/ThemeContext';
import _Text from 'Components/_Text/_Text';

const PxGStats = () => {
  const theme = useContext(ThemeContext);
  console.log('Rendering Home!');
  return (
    <div className={`PxGStats ${theme}`}>
      <_Text>PxGStats!</_Text>
    </div>
  );
};

export default PxGStats;
