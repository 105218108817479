import React, { useContext } from 'react';
import './Home.scss';
import ThemeContext from 'Core/Contexts/ThemeContext';
import _Text from 'Components/_Text/_Text';

const Home = () => {
  const theme = useContext(ThemeContext);
  console.log('Rendering Home!');
  return (
    <div className={`Home ${theme}`}>
      <_Text>Home!</_Text>
    </div>
  );
};

export default Home;
