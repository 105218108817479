import React, { useContext } from 'react';
import { ThemeContextType } from 'Types/System/ThemeContextType';
import './ActionBar.scss';
import ThemeContext from 'Core/Contexts/ThemeContext';
import _Text from 'Components/_Text/_Text';
import ThemePicker from 'Components/ActionBar/ThemePicker/ThemePicker';

interface ActionBarProps {
  themeChanger(v: ThemeContextType): void;
}
const ActionBar = (props: ActionBarProps) => {
  const { themeChanger } = props;
  const theme = useContext(ThemeContext);

  return (
    <div className={'ActionBar' + ` ${theme}`}>
      <_Text className='title-aligner' type={'title'}>
        Jean PSF
      </_Text>
      <ThemePicker themeChanger={themeChanger} />
    </div>
  );
};

export default ActionBar;
