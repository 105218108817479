import React, { useContext, useState } from 'react';
import './Calc.scss';
import ThemeContext from 'Core/Contexts/ThemeContext';
import { InputMask } from 'primereact/inputmask';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import _Text from 'Components/_Text/_Text';
import {
  analyzeAdult,
  analyzeElderly,
  analyzeKid,
  CalcData,
  getAge,
} from 'Pages/Calc/CalcAux';

const Calc = () => {
  const theme = useContext(ThemeContext);
  const [nascimento, setNascimento] = useState<string>('');
  const [leveModerado, setLeveModerado] = useState<number>();
  const [forteVigoroso, setForteVigoroso] = useState<number>();
  const [atividadeFisica, setAtividadeFisica] = useState<number>();
  const [alongamento, setAlongamento] = useState<number>();
  const [equilibrio, setEquilibrio] = useState<number>();
  const [result, setResult] = useState<string>('');

  return (
    <div className={`Calc ${theme}`}>
      <_Text>Calc!</_Text>
      <div>
        <label htmlFor='date'>
          <_Text>Nascimento</_Text>
        </label>
        <InputMask
          id='date'
          mask='99/99/9999'
          value={nascimento}
          placeholder='99/99/9999'
          slotChar='mm/dd/yyyy'
          onChange={(e) => {
            setNascimento(e.value);
          }}
        />
      </div>
      <_Text>
        Adicione o tempo de cada tipo de exercício que você pratica em minutos.
      </_Text>
      <div>
        <label htmlFor='exerLeveModerado'>
          <_Text>Exercício leve ou moderado.</_Text>
        </label>
        <InputNumber
          inputId='exerLeveModerado'
          value={leveModerado}
          onValueChange={(e) => {
            setLeveModerado(e.value);
          }}
          mode='decimal'
          useGrouping={false}
        />
      </div>
      <div>
        <label htmlFor='exerForteVigoroso'>
          <_Text>Exercício forte ou vigoroso.</_Text>
        </label>
        <InputNumber
          inputId='exerForteVigoroso'
          value={forteVigoroso}
          onValueChange={(e) => {
            setForteVigoroso(e.value);
          }}
          mode='decimal'
          useGrouping={false}
        />
      </div>
      <div>
        <label htmlFor='atividadeFisica'>
          <_Text>Atividade física.</_Text>
        </label>
        <InputNumber
          inputId='atividadeFisica'
          value={atividadeFisica}
          onValueChange={(e) => {
            setAtividadeFisica(e.value);
          }}
          mode='decimal'
          useGrouping={false}
        />
      </div>
      <div>
        <label htmlFor='alongamento'>
          <_Text>Alongamento.</_Text>
        </label>
        <InputNumber
          inputId='alongamento'
          value={alongamento}
          onValueChange={(e) => {
            setAlongamento(e.value);
          }}
          mode='decimal'
          useGrouping={false}
        />
      </div>
      <div>
        <label htmlFor='equilibrio'>
          <_Text>Equilibrio.</_Text>
        </label>
        <InputNumber
          inputId='equilibrio'
          value={equilibrio}
          onValueChange={(e) => {
            setEquilibrio(e.value);
          }}
          mode='decimal'
          useGrouping={false}
        />
      </div>
      <Button
        label='Submit'
        onClick={() => {
          const age: number = getAge(nascimento);
          const data: CalcData = {
            birthDate: nascimento,
            weakTime: leveModerado || 0,
            strongTime: forteVigoroso || 0,
            activityTime: atividadeFisica || 0,
            stretchingTime: alongamento || 0,
            balanceTime: equilibrio || 0,
          };
          switch (true) {
            case age < 18:
              setResult(analyzeKid(data));
              break;
            case age > 17 && age < 66:
              setResult(analyzeAdult(data));
              break;
            case age > 65:
              setResult(analyzeElderly(data));
              break;
            default:
              setResult(
                'Houve um erro ao calcular sua idade, por favor, verifique se a data de nascimento é valida.'
              );
          }
        }}
      />
      <div>
        <_Text>{result}</_Text>
      </div>
    </div>
  );
};

export default Calc;
