import React, { useCallback, useContext, useState } from 'react';
import Icon from 'Components/Icon/Icon';
import './ThemePicker.scss';
import Themes from 'Core/Themes';
import {
  ThemeContextConfig,
  ThemeContextType,
} from 'Types/System/ThemeContextType';
import ThemeContext from 'Core/Contexts/ThemeContext';

interface ThemePickerProps {
  themeChanger(v: ThemeContextType): void;
}
const ThemePicker = (props: ThemePickerProps) => {
  const { themeChanger } = props;
  const theme = useContext<ThemeContextType>(ThemeContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const renderActive = useCallback(() => {
    const aux = Themes.find((t: ThemeContextConfig) => theme === t.name);
    if (aux) {
      return (
        <Icon
          key={aux.name}
          icon={aux.icon}
          className={`icon-config ${theme}`}
        />
      );
    }
    return <Icon icon={'acorn'} className={`icon-config ${theme}`} />;
  }, [theme]);

  return (
    <div
      className={`ThemePicker ${theme}`}
      onMouseEnter={() => {
        setIsOpen(true);
      }}
      onMouseLeave={() => {
        setIsOpen(false);
      }}
    >
      {renderActive()}
      <div className={`dropdown ${isOpen ? ' isOpen' : ' isClosed'}`}>
        {Themes.filter((t: ThemeContextConfig) => t.name !== theme).map(
          (t: ThemeContextConfig) => (
            <div
              key={t.name}
              className={`theme-option ${theme}`}
              onClick={() => {
                themeChanger(t.name);
              }}
            >
              <Icon icon={t.icon} className={`icon-config ${theme}`} />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ThemePicker;
