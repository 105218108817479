import React, { useContext } from 'react';
import './Menu.scss';
import ThemeContext from 'Core/Contexts/ThemeContext';
import { Link } from 'react-router-dom';
import Routes, { Route } from 'Core/Routes';

const Menu = () => {
  const theme = useContext(ThemeContext);

  return (
    <nav className={`Menu ${theme}`}>
      <ul>
        {Routes.map((route: Route) => (
          <li key={route.name}>
            <Link to={route.path}>{route.name}</Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Menu;
