import React from 'react';
import styles from './Icon.module.scss';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';

type IconProps = FontAwesomeIconProps;
const Icon = (props: IconProps) => {
  return <FontAwesomeIcon {...props} />;
};

export default Icon;
