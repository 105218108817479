export interface CalcData {
  birthDate: string;
  weakTime: number;
  strongTime: number;
  activityTime: number;
  stretchingTime: number;
  balanceTime: number;
}

export const analyzeKid = (data: CalcData): string => {
  let result = '';
  if(data.activityTime >= 420){
    result = `Atividades físicas durante ${data.activityTime} minutos por dia é o suficiente, continue assim.`;
  } else {
    result = 'Considere adicionar atividades físicas durante seus treinos.';
  }
  return result;
};

export const analyzeAdult = (data: CalcData): string => {
  let isActive = true;
  let result = 'Os valores abaixo indicados são recomendações da ACSM (Colégio Americano de Medicina do Esporte) para aquisição de saúde (negritar, sublinhar aumentar a letra) através da prática de atividades e exercícios físicos.\n\n';

  if(data.strongTime >= 75){
    result = result + '\nParabéns! Suas atividades físicas fortes ou vigorosas estão dentro do recomendado, continue assim.'
  } else {
    result = result + `\nRecomenda-se acrescentar em ${75 - data.strongTime} minutos de atividades físicas fortes ou vigorosas durante seu treino.`
  }
  if(data.weakTime >= 100){
    result = result + `\nAtividades físicas leves ou moderadas durante ${data.weakTime} minutos por dia é o suficiente, continue assim.`;
  } else {
    result = result + `\nRecomenda-se acrescentar em ${100 - data.weakTime} minutos de atividades físicas fortes ou vigorosas durante seu treino.`
  }
  if(!(data.strongTime >= 75) && !(data.weakTime >= 100)){
    isActive = false;
  }

  let isGoodElderly = true;
  if(data.stretchingTime >= 20){
    result = result + `\nAtividades físicas de alongamento durante ${data.stretchingTime} minutos por dia são o suficiente, continue assim.`;
  } else {
    result = result + `\nConsidere adicionar ${20 - (data.stretchingTime || 0)} minutos de atividades de alongamento e flexibilidade em seus treinos.`
  }
  if(data.balanceTime >= 20){
    result = result + `\nAtividades físicas de equilíbrio ${data.balanceTime} minutos por dia são o suficiente, continue assim.`;
  } else {
    result = result + '\nConsidere adicionar atividades de equilíbrio em seus treinos.'
  }

  if(!(data.stretchingTime >= 20) || !(data.balanceTime >= 20)){
    isGoodElderly = false;
  }
  if(isGoodElderly){
    result = result + '\nParabéns, você esta no caminho para um envelhecimento saudável.';
  } else {
    result = result + '\n\n\nConsidere as recomendações de alongamento, flexibilidade e equilibrio para um envelhecimento saudável.';
  }


  result = result + `\n\n${isActive ? 'Você se classifica como uma pessoa ATIVA.' : 'Você se classifica como uma pessoa INSUFICIENTEMENTE ATIVA.'}`
  return result;
};

export const analyzeElderly = (data: CalcData): string => {
  let result = '';
  if(data.strongTime >= 75){
    result = result + `\nAtividades físicas fortes ou vigorosas durante ${data.strongTime} minutos por dia é o suficiente, continue assim.`
  } else {
    result = result + '\nConsidere adicionar atividades físicas fortes ou vigorosas durante seu treino.'
  }
  if(data.weakTime >= 150){
    result = result + `\nAtividades físicas leves ou moderadas durante ${data.weakTime} minutos por dia é o suficiente, continue assim.`;
  } else {
    result = result + '\nConsidere adicionar atividades físicas leves ou moderadas durante seu treino.'
  }
  if(data.stretchingTime >= 20){
    result = result + `\nAtividades físicas de alongamento durante ${data.stretchingTime} minutos por dia são o suficiente, continue assim.`;
  } else {
    result = result + '\nConsidere adicionar atividades de alongamento e flexibilidade em seus treinos.'
  }
  if(data.balanceTime >= 20){
    result = result + `\nAtividades físicas de equilíbrio ${data.balanceTime} minutos por dia são o suficiente, continue assim.`;
  } else {
    result = result + '\nConsidere adicionar atividades de equilíbrio em seus treinos.'
  }
  return result;
};

export const getAge = (birthDate: string): number => {
  const aux = birthDate.split('/');
  const today = new Date();
  const birth = new Date(parseInt(aux[2]), parseInt(aux[1]), parseInt(aux[0]));
  const age = today.getFullYear() - birth.getFullYear();
  const month = today.getMonth() - birth.getMonth();
  if(month < 0 || (month === 0 && today.getDate() < birth.getDate())){
    return age - 1;
  }
  return age;
}