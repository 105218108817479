import React, { useState } from 'react';
import ThemeContext from 'Core/Contexts/ThemeContext';
import './App.scss';
import { ThemeContextType } from 'Types/System/ThemeContextType';
import ActionBar from 'Components/ActionBar/ActionBar';
import Menu from 'Components/Menu/Menu';
import Themes from 'Core/Themes';
import { BrowserRouter } from 'react-router-dom';
import PagesHandler from 'Components/PagesHandler/PagesHandler';

function App() {
  const [theme, setTheme] = useState<ThemeContextType>(Themes[0].name);

  return (
    <ThemeContext.Provider value={theme}>
      <BrowserRouter>
        <div className={`App ${theme}`}>
          <ActionBar
            themeChanger={(t: ThemeContextType) => {
              setTheme(t);
            }}
          />
          <Menu />
          <PagesHandler />
        </div>
      </BrowserRouter>
    </ThemeContext.Provider>
  );
}

export default App;
