import React, { ReactElement } from 'react';
import Home from 'Pages/Home/Home';
import PxGStats from 'Pages/PxGStats/PxGStats';
import Calc from 'Pages/Calc/Calc';

export interface Route {
  name: string;
  path: string;
  component: ReactElement;
}

const Routes: Route[] = [
  {
    name: 'calc',
    path: '/calc',
    component: <Calc />,
  },
  {
    name: 'pxgstats',
    path: '/pxgstats',
    component: <PxGStats />,
  },
  {
    name: 'home',
    path: '/',
    component: <Home />,
  },
];

export default Routes;
