import React, { useContext } from 'react';
import './PagesHandler.scss';
import ThemeContext from 'Core/Contexts/ThemeContext';
import { Switch, Route as NavigationRoute } from 'react-router-dom';
import Routes, { Route } from 'Core/Routes';

const PagesHandler = () => {
  const theme = useContext(ThemeContext);
  return (
    <div className={`PagesHandler ${theme}`}>
      <Switch>
        {Routes.map((route: Route) => (
          <NavigationRoute key={route.name} path={route.path}>
            {route.component}
          </NavigationRoute>
        ))}
      </Switch>
    </div>
  );
};

export default PagesHandler;
