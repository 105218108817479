import { ThemeContextConfig } from 'Types/System/ThemeContextType';

const Themes: ThemeContextConfig[] = [
  {
    name: 'theme-dark',
    icon: 'moon'
  },
  {
    name: 'theme-light',
    icon: 'sun'
  },
];

export default Themes;